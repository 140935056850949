import * as React from 'react'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { IconButton, Stack } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

export const PartnershipsHero = () => (
  <ParallaxGroup>
    <ParallaxLayer depth={-1}>
      <StaticImage
        src='../../images/background-partnerships.jpeg'
        alt='Entrada del restaurant'
        layout='fullWidth'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        quality={90}
      />
    </ParallaxLayer>
    <ParallaxLayer
      bgcolor='rgba(0, 0, 0, 0.55)'
      color='background.default'
    >
      <Stack
        p={3}
        height='100%'
        alignItems='center'
        justifyContent='center'
      >
        <CursiveTypography variant='h1'>
          Convenios, Alianzas y Agencias
        </CursiveTypography>
      </Stack>
      <IconButton
        href='#conveniosyalianzas'
        sx={(theme) => ({
          color: 'background.default',
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: theme.spacing(5),
        })}
      >
        <ArrowCircleDownIcon />
      </IconButton>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default PartnershipsHero
