import * as React from 'react'

import BookButton from '../components/book_button'
import NavBar from '../components/nav_bar'
import { Parallax } from '../components/parallax'
import SeoHeaders from '../components/seo_headers'
import SocialButtons from '../components/social_buttons'
import LandingFooter from '../sections/landing/footer'
import PartnershipsContent from '../sections/partnerships/content'
import PartnershipsHero from '../sections/partnerships/hero'

const AlliancesPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        title='Convenios, Alianzas y Agencias'
        description={(
          "Lleva tu empresa o agencia turística a Ocean Pacific's y comparte"
          + ' una experiencia inolvidable.'
        )}
      />
      <NavBar />
      <SocialButtons />
      <BookButton />
      <Parallax>
        <PartnershipsHero />
        <PartnershipsContent />
        <LandingFooter />
      </Parallax>
    </React.Fragment>
  )
}

export default AlliancesPage
