import * as React from 'react'

import MailIcon from '@mui/icons-material/MailOutline'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

import type { GridProps, StackProps } from '@mui/material'

const PartnershipsMailButton = () => (
  <Box>
    <Button
      target='_blank'
      rel='noopener noreferrer'
      href='mailto:comercial@oceanpacifics.cl'
      startIcon={<MailIcon />}
      variant='outlined'
      size='large'
    >
      Escríbenos
    </Button>
  </Box>
)

const TextContainer = ({ children }: { children: StackProps['children'] }) => (
  <Grid
    item
    xs={12}
    md={6}
  >
    <Stack
      spacing={4}
      alignItems='center'
      justifyContent='center'
      maxWidth='440px'
      height='100%'
      mx='auto'
      px={3}
      py={6}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          alignItems: 'flex-start',
        },
      })}
    >
      {children}
    </Stack>
  </Grid>
)

const ImageContainer = ({ children }: { children: GridProps['children'] }) => (
  <Grid
    item
    xs={12}
    md={6}
  >
    {children}
  </Grid>
)

export const PartnershipsContent = () => (
  <ParallaxGroup
    id='conveniosyalianzas'
    sx={(theme) => ({
      height: '1600px !important',
      [theme.breakpoints.up('md')]: {
        height: '840px !important',
      },
    })}
  >
    <ParallaxLayer bgcolor='background.default'>
      <Grid container>
        <TextContainer>
          <CursiveTypography variant='h2'>
            Convenios y Alianzas
          </CursiveTypography>
          <Typography textAlign='justify'>
            Con el fin de poder compartir la experiencia que es
            Ocean Pacific&apos;s hemos creado un programa de convenios y
            alianzas especiales para instituciones y empresas. Para conocer
            más detalles, envíanos un correo presionando el botón:
          </Typography>
          <PartnershipsMailButton />
        </TextContainer>
        <ImageContainer>
          <StaticImage
            src='../../images/partnerships/salon-prat.jpeg'
            alt={'Salón Prat'}
            objectFit='cover'
            quality={90}
            style={{ height: '420px' }}
          />
        </ImageContainer>
        <ImageContainer>
          <StaticImage
            src='../../images/partnerships/salon-calabozo.jpg'
            alt={'Salón Calabozo'}
            objectFit='cover'
            quality={90}
            style={{ height: '420px' }}
          />
        </ImageContainer>
        <TextContainer>
          <CursiveTypography variant='h2'>
            Experiencia Turística
          </CursiveTypography>
          <Typography textAlign='justify'>
            Además, si eres parte de una agencia de turismo, contáctanos y
            trabaja con nosotros para entregarle a tus clientes una experiencia
            que recordarán por siempre.
          </Typography>
          <PartnershipsMailButton />
        </TextContainer>
      </Grid>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default PartnershipsContent
